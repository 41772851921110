@import "../../Assets/Styles/variables.scss";

.setting-main-wrapper {
  padding-bottom: 0;

  .setting-sidebar-col {
    flex: 0 0 270px;
    max-width: 270px;
  }
  .setting-content-bar-col {
    flex: 0 0 calc(100% - 270px);
    max-width: calc(100% - 270px);
  }

  .setting-sidebar {
    width: 270px;
    padding: 1.5rem 0;
    min-height: calc(100vh - 52.36px);
    box-shadow: 0 0 6px 0 #dae3ecba;
    height: 100%;

    h3 {
      padding: 0 1rem;
    }

    .setting-toggle-button {
      display: none;
    }

    .nav {
      flex-direction: column;

      a {
        padding: 0.5rem 1.5rem;
        cursor: pointer;
        color: $default-color;
        display: flex;
        align-items: center;
        transition: all ease-in 0.3s;
        position: relative;
        font-weight: 600;
        text-decoration: none;
        font-size: 0.85rem;

        i {
          font-size: 1.1rem;
          margin-right: 0.7rem;
          font-weight: 500;
        }

        &.active,
        &:hover {
          font-weight: 600;
          background-color: $light-color;
          color: $primary-color;

          &:before {
            content: "";
            position: absolute;
            height: 100%;
            width: 4px;
            left: 0px;
            background-color: $primary-color;
          }
        }
      }
    }
  }

  .setting-content-bar {
    padding-left: 1rem;
    padding-right: 1rem;

    .setting-company-detail {
      .setting-company-detail-inner {
        display: flex;
        flex-direction: row;

        .company-profile {
          width: 100px;
          height: 100px;
          border-radius: 50%;
          background: #ddd;
          border: 1px solid $border-color;
          flex-shrink: 0;

          img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            object-fit: cover;
          }
        }

        .company-form-area {
          width: 100%;
          padding-left: 1.5rem;
        }
      }
    }
  }

  .email-linked-account-suite {
    i {
      color: #e24a33;
      vertical-align: middle;
      margin-right: 0.2rem;
    }

    img {
      max-width: 22px;
    }

    p {
      font-size: $text-size-sm;
      font-family: $font-montserrat;
      font-weight: 600;
      display: flex;
      overflow: hidden;
      gap: 0.4rem;
    }
  }

  .enter-role-name {
    .form-group {
      display: flex;
      align-items: center;
      gap: 1.5rem;

      label {
        color: $default-color;
        text-transform: none;
      }

      input {
        max-width: 300px;
      }
    }
  }

  .setting-email-signature {
    background-color: $bg-light;
    display: flex;
    gap: 1.5rem;
    padding: 2rem;
    .user-image-signature {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      overflow: hidden;
      flex-shrink: 0;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .user-detail-signature {
      h3 {
        text-transform: uppercase;
        margin-bottom: 0.2rem;
      }

      .social-media-circle-icons {
        margin-top: 1rem;
        a {
          width: 32px;
          height: 32px;
          line-height: 32px;
          margin-right: 0.4rem;
          margin-left: 0;
        }
      }
    }
  }

  .email-stats {
    text-align: center;
    img {
      max-width: 350px;
    }
  }

  .crm-permission-outer {
    .crm-permission-list {
      display: flex;
      gap: 0.5rem;
      flex-direction: column;

      .permission-role-name {
        min-width: 150px;
        font-family: $font-montserrat;
        font-weight: 600;
        display: flex;
        align-items: center;
        gap: 1rem;
        margin-bottom: 0.5rem;

        .switch-permission {
          margin-bottom: 0;
        }
      }

      .permission-role-type {
        display: flex;
        flex-wrap: wrap;

        .permission-role-list {
          // text-align: center;
          margin: 0 1.2rem 1rem 0;

          .custom-control-label {
            margin-bottom: 0.6rem;
            font-family: $font-montserrat;
            font-weight: 500;
            font-size: 0.875rem;
          }

          .switch-field {
            border-radius: 30px;
            overflow: hidden;
            border: 1px solid $primary-color;

            label {
              border: 0;
              padding: 0.4rem 0.3rem;
              min-width: 2.6rem;
              font-size: 0.7rem;
              font-weight: 600;
              line-height: 0.7rem;
              text-transform: none;
              color: $default-color;
            }

            :checked + label {
              color: #fff;
            }
          }
        }
      }
    }
  }

  .checkbox-td {
    text-align: center;
  }

  .setting-payment-gateway {
    padding-top: 2rem;
    .card-style-2 {
      position: relative;
      padding-top: 2rem;
      text-align: center;
    }
    .payment-gateway-inner {
      .payment-gateway-logo {
        position: absolute;
        box-shadow: 0 0 13px 0 #dae3ecba;
        background-color: #fff;
        padding: 0.5rem 1.5rem;
        border-radius: 10px;
        font-weight: 600;
        left: 50%;
        top: 0;
        transform: translate(-50%, -50%);
        max-width: 130px;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          max-width: 100%;
        }
      }

      p {
        min-height: 4.7rem;
      }

      .payment-gateway-inner-bottom {
        display: flex;
        justify-content: space-between;

        .payment-card-icons {
          display: flex;
          align-items: center;
          gap: 2px;
          span {
            display: inline-block;
            height: 24px;
          }
        }
      }
    }

    &.payment-gateway-success {
      .payment-gateway-inner {
        p {
          min-height: auto;
        }
      }
    }
  }

  .setting-plugins {
    padding-top: 2rem;
    .card-style-2 {
      position: relative;
      padding-top: 2rem;
      text-align: center;
    }
    .setting-plugins-inner {
      text-align: center;
      .plugins-logo {
        position: absolute;
        box-shadow: 0 0 13px 0 #dae3ecba;
        background-color: #fff;
        padding: 0 1rem;
        border-radius: 10px;
        font-size: 1.9rem;
        font-weight: 600;
        left: 50%;
        top: 0;
        transform: translate(-50%, -50%);
        max-width: 130px;
        img {
          max-width: 100%;
        }
      }
      .btn {
        margin: auto;
      }
    }
  }

  .team-members-tab-content {
    .card {
      box-shadow: 0 0 0 0;
      border: 0;

      .card-body {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  .team-member-basic-details {
    .user-name-fl {
      width: 100%;
      height: 100%;
      background-color: $primary-color;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 2rem;
      font-weight: 600;
      color: #fff;
      font-family: $font-montserrat;
    }
    .user-contact-details-2 {
      h4 {
        margin-bottom: 0.2rem;
      }
      h6 {
        color: $default-color;
        opacity: 0.75;
        margin-bottom: 0;
      }
    }

    .crm-main-details {
      margin-top: 2rem;
    }

    .team-member-profile-footer {
      display: flex;
      justify-content: flex-end;
      gap: 0.5rem;
    }
  }

  .user-last-login {
    font-size: $text-size-xs;
    font-weight: 600;

    span {
      color: $default-color;
      opacity: 0.75;
    }
  }

  .copy-api-key {
    cursor: pointer;
    i {
      font-size: 1.3rem;
    }
  }

  .equp-plans {
    .equp-plans-inner {
      border-top: 2px solid $primary-color;
      box-shadow: 0 0 13px 0 #dae3ecba;
      padding: 2rem 1.5rem;

      h4 {
        font-weight: 700;
      }

      ul {
        list-style: none;
        padding-left: 0;
        margin: 2rem 0;
        li {
          padding: 0.8rem 0;
          padding-left: 3rem;
          position: relative;

          span {
            position: absolute;
            left: 0;
            width: 26px;
            height: 26px;
            line-height: 26px;
            text-align: center;
            background: $light-blue-color;
            color: $primary-color;
            border-radius: 50%;
          }
        }
      }
      .plans-btn {
        display: flex;
        gap: 1rem;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;

        .btn {
          padding: 0.5rem 0.8rem;
          font-size: $text-size-xxs;
        }
      }
    }
  }

  .setting-add-ons {
    .setting-add-ons-inner {
      border-top: 2px solid $color-green;
      box-shadow: 0 0 13px 0 #dae3ecba;
      padding: 1.5rem 1.5rem;

      .add-ons-price {
        display: flex;
        font-weight: 600;
        justify-content: space-between;
        span {
          &:last-child {
            color: $color-green;
          }
        }
      }
    }
  }

  .setting-add-payment-method {
    border: 1px solid $border-color;
    text-align: center;
    padding: 3rem 2rem;
    margin: 1rem 0;

    .payment-icon {
      span {
        display: inline-flex;
        width: 60px;
        height: 60px;
        background: $light-color;
        color: $primary-color;
        font-size: 2rem;
        border-radius: 50%;
        align-items: center;
        justify-content: center;
      }
    }

    p {
      margin-top: 1rem;
      font-size: $text-size-sm;
    }
  }

  .inventory-graph {
    box-shadow: 0 0 7px #ddd;
    padding: 1rem 0;
    height: 100%;
  }

  table {
    &.tracking-script-table {
      .form-control {
        min-width: 200px;
      }
    }
  }
}

.choose-payment-method {
  display: flex;
  gap: 2rem;
  justify-content: center;

  input {
    position: absolute;
    left: -9999px;
  }

  label {
    box-shadow: 0 0 13px 0 #dae3ecba;
    padding: 0.5rem 1.5rem;
    border-radius: 5px;
    font-weight: 600;
    font-family: $font-montserrat;

    .choose-payment-method-inner {
      span {
        background-color: $light-blue-color;
        display: inline-block;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        text-align: center;
        line-height: 50px;
        margin-right: 1rem;

        img {
          max-width: 30px;
        }
      }
    }
  }

  input[type="radio"]:checked + label > img {
    border: 1px solid #fff;
  }

  input[type="radio"]:checked + label {
    background-color: $color-green;
    color: #fff;
  }
}

.payment-method-details {
  margin-top: 1rem;
  .choose-billing-address {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    h6 {
      margin-bottom: 0;
    }
  }
}

.saved-card-details {
  .saved-card-detail-list {
    position: relative;
    max-width: 500px;
    h6 {
      font-weight: 500;
    }

    h5 {
      margin-bottom: 0;
    }

    input {
      position: absolute;
      top: 50%;
      right: 1.5rem;
      transform: translate(-0%, -50%);
    }

    label {
      display: flex;
      justify-content: space-between;
      gap: 1.5rem;

      box-shadow: 0 0 13px 0 #dae3ecba;
      padding: 0.8rem 1.5rem;
      border-radius: 5px;
      font-weight: 600;
      font-family: $font-montserrat;

      span {
        background-color: $light-blue-color;
        display: inline-block;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        text-align: center;
        line-height: 50px;

        img {
          max-width: 30px;
        }
      }

      .card-details-left {
        display: flex;
        align-items: center;
        gap: 1.5rem;
        user-select: none;
      }
    }

    .default-card {
      color: $default-color;
      display: flex;
      align-items: center;
      font-size: 0.75rem;
      padding-right: 1.5rem;
    }
  }
}

.payment-method-paypal {
  .setting-add-payment-method {
    border: 1px solid $border-color;
    text-align: center;
    padding: 3rem 2rem;
    margin: 1rem 0;

    .payment-icon {
      span {
        display: inline-flex;
        width: 60px;
        height: 60px;
        background: #e1effc;
        color: #4491e6;
        font-size: 2rem;
        border-radius: 50%;
        align-items: center;
        justify-content: center;
      }
    }

    p {
      margin-top: 1rem;
      font-size: $text-size-sm;
      font-weight: 600;
      text-transform: uppercase;
    }
  }
}

// Profile Page

.setting-main-wrapper {
  .add-team-member-sidebar {
    min-height: 300px;

    .card {
      height: 100%;
    }

    .avtar {
      width: 60px;
      height: 60px;

      img {
        width: 60px;
        height: 60px;
      }

      .user-name-fl {
        width: 100%;
        height: 100%;
      }
    }
  }
}

// End Profile Page

@media (max-width: 768px) {
  .setting-main-wrapper {
    .setting-sidebar-col {
      flex: 0 0 100%;
      max-width: 100%;
    }
    .setting-content-bar-col {
      flex: 0 0 100%;
      max-width: 100%;
    }

    .setting-sidebar {
      width: 100%;
      min-height: auto;
      position: relative;
      padding: 0.5rem 1rem;
      .row {
        align-items: center;
      }

      h3 {
        margin-bottom: 0;
        padding: 0;
      }

      .setting-toggle-button {
        border: 0;
        background-color: transparent;
        display: block;

        &:focus {
          border: 0;
          background-color: transparent;
          outline: 0;
        }

        i {
          font-size: 1.2rem;
          color: $default-color;
        }
      }

      .nav {
        max-height: 0;
        overflow: hidden;
        flex-flow: column;
        transition: all ease 0.3s;
      }

      .setting-sidebar-nav {
        max-height: 800px;
        padding-top: 1rem;
      }
    }
    .setting-content-bar {
      padding: 0;
      .setting-company-detail {
        .setting-company-detail-inner {
          .company-profile {
            width: 50px;
            height: 50px;
            img {
              width: 50px;
              height: 50px;
            }
          }

          .company-form-area {
            padding-left: 1rem;
          }
        }
      }

      .setting-plugins,
      .setting-payment-gateway {
        padding-top: 0;
        .col-sm-4 {
          margin-top: 2rem;
        }
      }

      .payment-gateway-success {
        padding-top: 2rem;
      }
    }

    .add-team-member-sidebar {
      min-height: auto;
      margin-bottom: 1.5rem;
    }
    .team-member-basic-details {
      .crm-main-details {
        margin-top: 1rem;
      }
      .user-name-fl {
        width: 50px;
        height: 50px;
        font-size: 1.7rem;
      }
    }

    .crm-permission-outer {
      .crm-permission-list {
        flex-direction: column;

        .permission-role-name {
          margin-bottom: 0.5rem;
        }

        .permission-role-type {
          .permission-role-list {
            margin-left: 0;
            margin-bottom: 0;
          }
        }
      }
    }
    .enter-role-name {
      .form-group {
        label {
          flex-shrink: 0;
        }
      }
    }

    .setting-add-ons {
      margin-bottom: 1rem;
    }

    .setting-add-payment-method {
      margin: 0 0;
    }
  }
}

@media (max-width: 767px) {
  .setting-main-wrapper {
    .inventory-graph {
      margin-bottom: 1rem;
      height: auto;
    }

    .setting-email-signature {
      padding: 1rem;
      gap: 1rem;

      .user-image-signature {
        width: 50px;
        height: 50px;
      }

      .user-detail-signature {
        h3 {
          font-size: $text-size-sm;
        }
      }
    }

    .email-linked-account-suite {
      margin: 1rem 0;
    }
    .score-meter-outer {
      .score-meter-inner {
        margin-top: 1rem;
      }
      .meter-scale {
        margin-top: 0.3rem;
        li {
          font-size: 0.6rem;
        }
      }
    }
    table {
      .form-control {
        min-width: 100px;
      }
    }
  }
  .booking-sub-header {
    margin-bottom: 0rem !important;
  }
}
