$default-color: #566678; //#2a3950
$light-color: #e7e7ff;
$extra-light-color: #fbfbff;
$primary-color: #614fba;
$primary-color-active: #5f61e6;
$success-color: #63ba3c;
$success-color-active: #5eb635;
$danger-color: #f4584d;
$warning-color: #fda918;
$dark-blue-color: #2d629b;
$light-blue-color: #e3effb;
$light-green-color: #e8f4d9;
$light-brown-color: #b39059;
$orange-color: #f27428;
$light-orange-color: #f8ede6;
$purple-color: #a645d0;
$light-purple-color: #f1e4f7;
$bg-dark: #e7e7ff;
$bg-medium: #fbfafa;
$bg-light: #fbfbfb;
$border-color: #c7cdd4;
$icon-color: #0874ea;

// Default colors
$color-orange: #fda918;
$color-green: #58c29a;
$color-blue: #007bff;
$light-color-blue: #e7f3ff;
$color-red: #dc3545;
$color-dark-green: #17a2b8;
$color-light-red: #ff684e;
$active-blue: #6e5dc6;
$active-green: #9ac363;
$active-orange: #fb9b51;
$whatsapp-color: #058c7d;
$error-color: #ff2400;

// Font Size
$text-size-lg: 2rem;
$text-size-md: 1.875rem;
$text-size-sm: 1rem;
$text-size-xs: 0.875rem;
$text-size-xxs: 0.75rem;

// Social Media color

$facebook-color: #3a589e;
$linkedin-color: #0b75b3;
$google-color: #e1493b;
$twitter-color: #1cb7eb;

// Font Family
$font-montserrat: "Montserrat", sans-serif;

@mixin flex-center {
  display: flex;
  align-items: center;
}
@mixin form-control {
  font-family: "Open Sans", sans-serif;
  font-size: 0.875rem;
  height: calc(1.6em + 0.75rem + 2px);
  border-radius: 0.15rem;
  padding: 0.375rem 0.85rem;
  border: 0.063rem solid #e3e3e3;
  color: $default-color;
  width: 100%;
  /*-webkit-text-stroke: 0.1px;
    -webkit-text-stroke-color: $default-color;*/
  &:focus {
    box-shadow: 0 0 0 0.063rem $primary-color;
    border-color: $primary-color;
    outline: none;
  }
}
