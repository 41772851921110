@import "variables.scss";

@media (max-width: 1024px) {
	.tab-header {
		.nav {
			flex-wrap: nowrap;
			overflow-x: auto;
			overflow-y: hidden;
		}

		.nav-item {
			.nav-link {
				height: 100%;
				white-space: nowrap;
			}
		}
	}
}

@media (min-width: 768px) and (max-width: 1024px) {
	div {
		.filter-tags-sec {
			.save-filter-form {
				.form-control {
					min-width: 150px;
				}
			}
		}
	}
}

@media (min-width: 767px) and (max-width: 1024px) {
	body {
		.setting-main-wrapper {
			.setting-payment-gateway {
				.payment-gateway-inner {
					.payment-gateway-inner-bottom {
						flex-direction: column;
						gap: 0.8rem;
					}
				}
			}
		}

		.booking-preferences-iner {
			.select-date-range-inner {
				text-align: left;
				.DayPicker-wrapper {
					.DayPicker-Months {
					}
					.DayPicker-Month {
						width: 280px;
					}
				}
			}
			.set-off-calendar {
				.DayPicker-Month {
					width: 100%;
				}
			}
		}

		.lt1-card {
			padding: 1rem;
		}
	}

	.container {
		width: 100%;
	}
}

@media (max-width: 768px) {
	body {
		// font-size: $text-size-xs !important;

		::-webkit-scrollbar {
			width: 2px;
			height: 2px;
		}

		.deal-status-step {
			&::-webkit-scrollbar {
				height: 2px;
			}
		}

		// Default Classes //

		.card-style-2 {
			margin-bottom: 1rem;
			height: calc(100% - 1rem);
		}

		.navbar {
			padding-right: 0.3rem;

			.navbar-brand {
				img {
					max-width: 180px;
				}
			}
			.navbar-collapse.show {
				z-index: 9999;
			}

			.navbar-nav {
				.nav-link {
					padding: 0.85rem 0.6rem;
				}
				&.admin-action-navbar {
					.dropdown-toggle {
						padding-left: 0.75rem;
						padding-right: 0.75rem;
					}
				}
			}

			.navbar-collapse {
				.navbar-nav {
					.dropdown-menu {
						a {
							background-color: transparent;
							padding: 0.75rem 2rem;
							// color: #fff;

							&:hover,
							&:focus {
								color: #fff;
							}
						}
					}
				}
			}
			.shortcut-dropdown {
				display: none;
			}
			.mobile-search-toggle {
				display: none;
			}
		}

		.fixed-height-basic-details,
		.tab-equal-sidebar-1 .card-body,
		.fixed-height-tags,
		.deal-status-card {
			height: auto;
		}

		.recent-interaction-fixed-h {
			margin-bottom: 1.5rem;
		}

		.cnvrstin-item {
			.cnvrstin-msg {
				padding: 1rem;
			}
		}
		.sub-head {
			padding: 0.875rem 0 0.625rem 0;
			.row {
				align-items: center;
			}
		}
		.preview-hover {
			.btn-preview {
				opacity: 1;
			}
		}

		.table-wrap {
			.table-responsive {
				min-height: auto;
			}
		}

		.deal-status-item {
			h5 {
				font-size: $text-size-xs;
			}
			span {
				font-size: $text-size-xs;
			}
		}
		.deal-status-step {
			margin-bottom: 1.5rem;
			.deal-step-item {
				button {
					font-size: $text-size-xxs;
				}
			}
		}

		.campaign-analysts-card {
			.card-body {
				.analysts-card-outer-col {
					margin-top: 1rem;
				}
			}
		}
	}
	.alert-outer-col {
		position: absolute;
    // z-index: 99999;
    // width: 100%;
    // max-width: 500px;
    // left: 50%;
    // top: 6px;
    // transform: translate(-50%, -0%);
	}

	.alert-outer-main {
		position: fixed;
    top: 4rem;
    left: 1rem;
    right: 1rem;
    z-index: 99;
    box-shadow: 0 0 15px #0000001f;
	}
}

// Responsive for Mobile

@media (max-width: 767px) {
	body {
		.main-content {
			padding-bottom: 1rem;
		}

		.card {
			.card-body {
				padding: 1rem;
			}
		}
		.custom-table {
			td {
				max-width: 500px;
			}
		}

		.modal-dialog {
			margin: 0;
			// padding: 0.5rem;
		}

		.right {
			.modal-dialog {
				padding: 0;
			}

			.modal-header {
				padding-left: 1rem;
				padding-right: 1rem;
			}

			.modal-body {
				padding-left: 1rem;
				padding-right: 1rem;
			}
		}

		.table {
			th,
			td {
				padding: 0.6rem 0.8rem;
			}

			// thead {
			//   th {
			//     font-size: $text-size-xxs;
			//   }
			// }
		}

		.custom-table .preview-hover {
			// z-index: -1;
		}

		// Fileter
		.filter-tags-sec {
			display: flex;
			flex-direction: column;
			padding-right: 0;

			.filter-tags-wrap {
				flex-wrap: unset;
			}

			.save-filter-form {
				position: relative;

				form {
					flex-flow: row;

					.btn {
						flex: 0 0 auto;
						width: auto;
						max-width: 100%;
					}
				}
			}
		}

		.sub-header {
			margin-bottom: 2.125rem;

			.row {
				align-items: center;
			}

			.align-items-end {
				align-items: center !important;
			}

			h2 {
				font-size: $text-size-sm;
			}

			p {
				font-size: $text-size-xs;
			}
		}

		.tag {
			i {
				right: 5px;
			}
		}

		.chat-main-tab {
			.chat-content-inner {
				.chat-header-inner {
					padding: 0.8rem 0.5rem;

					.chat-icon-header {
						span {
							display: none;
						}
					}
				}
				.chat-footer {
					padding: 0.8rem 0.5rem;
					.emoji-attech-icon {
						width: 4rem;
					}
					.message-typing-area {
						width: calc(100% - 4rem);
					}
				}
			}
		}

		.call-log-popup {
			.call-log-content {
				.chat-header-inner {
					padding: 0.8rem 1rem;

					.chat-icon-header {
						span {
							display: none;
						}
					}
				}
				.call-log-body {
					padding: 1rem;

					.call-note-main {
						.call-note-inner {
							padding: 1rem;
							margin-bottom: 1.5rem;
						}
					}
				}
			}
		}

		.deal-status-head {
			.deal-head-item {
				box-shadow: 0 0px 6px 0 #dae3ecba;
				border-right: 0;
				padding: 0.7rem;
				border-radius: 5px;
				height: calc(100% - 20px);
			}
		}

		.form-group-overlap-label {
			.form-label {
				top: 12px;
			}

			.form-control {
				padding-left: 4.2rem;
				padding-right: 0;
			}

			.right-overlap {
				top: 12px;
			}

			&:first-child {
				.form-control {
					padding-right: 4.2rem;
				}
				.right-overlap {
					top: 8px;
					span {
						margin-left: 0.7rem;
					}
				}
			}
		}

		.cnvrstin-item {
			.cnvrstin-editor {
				padding: 1rem 0.763rem;

				.form-group-overlap-label {
					.form-control {
						padding-left: 4.2rem;
						padding-right: 4rem;
					}

					.right-overlap {
						top: 8px;
						span {
							margin-left: 0.7rem;
						}
					}
				}
			}
		}

		.coupon-badge {
			width: 26px;
			height: 26px;

			&::before,
			&::after {
				height: 26px;
				width: 26px;
			}
			span {
				font-size: $text-size-xxs;
			}
		}

		.navbar {
			.admin-action-navbar {
				.dropdown-menu {
					width: 220px;

					.notification-title {
						&::before {
							right: 64px;
						}
					}
				}
			}
		}

		// Service
		.import-step {
			gap: 0.5rem;
			flex-direction: row;
			white-space: nowrap;
			padding: 0.3rem 2px 0.3rem !important;
			overflow: auto;
			margin-top: 0;
			margin-bottom: 1rem;

			.step-item {
				background-color: #fff;
				box-shadow: 0 0 4px 0 #dae3ecba;
				padding: 0.5rem 0.8rem;
				padding-top: 1rem;
				border-radius: 5px;

				&::before {
					opacity: 0;
					visibility: hidden;
				}

				&.active {
					background-color: $primary-color;

					button {
						color: #fff;

						&::before {
							background-color: #fff;
							color: $primary-color;
							line-height: 2rem;
							font-size: 1.4rem;
						}
					}
				}

				button {
					font-size: 0.75rem;

					&::before {
						top: 1rem;
						height: 2rem;
						width: 2rem;
						line-height: 2rem;
						font-size: 1rem;
					}
				}
			}
		}

		.set-availability-tabs-wrap {
			padding: 1rem 0;
		}

		.select-layout-div{
			.lt1-card {
				max-width: 100%;
			}
			.add-more-plan-card {
				height: auto;

				.add-more-plan {
					min-height: 150px;
				}
			}
		}

		.booking-preferences-iner {
			.select-date-range-inner {
				text-align: left;
				.DayPicker-wrapper {
					.DayPicker-Months {
						flex-direction: column;
					}
					.DayPicker-Month {
						width: 100%;
						margin: 0;
					}
				}
			}
			.set-off-calendar {
				.DayPicker-Month {
					width: 100%;
				}
			}
			.choose-set-offs {
				.set-particular-day-off {
					margin-bottom: 0.5rem;
				}
			}
		}
		.trapezium-tabs-wrap {
			.tab-content {
				padding: 1rem 1rem;
			}
		}

		.uploaded-imgs-sec {
			.col {
				.uploaded-item {
					span {
						width: 1.075rem;
						height: 1.075rem;
						font-size: 0.8rem;
					}
				}
			}
		}
		.booking-summary-main {
			.booking-summary-inner {
				.checkout-detail-row {
					display: flex;
					flex-direction: column-reverse;
					gap: 10px;
					margin-bottom: 0;
				}
			}
		}

		.add-quote-table {
			.form-inline {
				flex-flow: row;
			}

			.minus-plus-action {
				flex-flow: row;
			}
		}

		.add-quote-table {
			.custom-table {
				td {
					max-width: 100%;
				}
			}
		}

		.address-accordion-bs {
			.card {
				.card-header {
					.same-ad-chk {
						display: block;
					}
				}
			}
		}

		.choose-payment-method {
			gap: 1rem;

			> div {
				flex: 0 0 50%;
				max-width: calc(50% - 0.5rem);
			}

			label {
				padding: 0.5rem 0.8rem;
				font-size: $text-size-xxs;
				width: 100%;

				.choose-payment-method-inner {
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					gap: 0.5rem;
					span {
						margin-right: 0;
					}
				}
			}
		}
		.saved-card-details {
			.saved-card-detail-list {
				input {
					top: unset;
					bottom: 0.5rem;
				}
				label {
					display: block;
					.card-details-left {
						display: block;
						padding-left: 50px;

						span {
							position: absolute;
							left: 1rem;
							width: 40px;
							height: 40px;
							line-height: 40px;
						}
					}
				}
				.default-card {
					justify-content: flex-end;
				}
			}
		}

		.campaign-compare-main {
			&::before {
				display: none;
			}
			.compare-top-bar-main-new {
				padding: 0;

				.campaign-ab-top {
					width: 100%;
					max-width: 100%;
					padding: 0.5rem 0.3rem;
					h4 {
						font-size: $text-size-xs;
					}
				}

				ul {
					display: table;
					width: 100%;
					li {
						display: table-cell;
						font-size: $text-size-xxs;
						width: 25%;
						padding: 0.5rem 0.5rem;

						&:nth-child(2) {
							width: 52%;
							max-width: 52%;
							font-size: $text-size-xxs;

							span {
								width: auto;
								display: block;
							}

							i {
								font-size: $text-size-xs;
								margin-right: 0.1rem;
							}
						}
					}
				}
			}
			.campaign-compare-graph-area {
				&::before {
					display: none;
				}

				.card {
					.card-body {
						padding-left: 0;
						padding-right: 0;
					}
				}
			}
			.css-2b097c-container {
				max-width: 140px;
				min-width: 140px;
			}
			.campaign-title-main {
				left: 0;
				right: 0;

				.campaign-title-inner {
					font-size: 1rem;
				}
			}
			.campaign-title-main {
				display: none;
			}
		}

		.campaign-analytics-main {
			.analytics-top-bar-main {
				> .row {
					margin-left: -8px;
					margin-right: -8px;
					> .col {
						flex: 0 0 50%;
						max-width: 50%;
						padding-left: 8px;
						padding-right: 8px;
					}
				}

				.analytics-top-box {
					margin-bottom: 1rem;
					padding: 0.6rem 0.5rem;
					padding-left: 45px;
					height: calc(100% - 1rem);

					span {
						left: 0.5rem;
						font-size: 1.3rem;
						width: 30px;
						height: 30px;
						line-height: 30px;
						top: 13px;
						transform: translate(0%, -0%);
					}

					p {
						font-size: $text-size-xxs;
						margin-bottom: 0.1rem;
					}

					h4 {
						font-size: 1.3rem;
					}
				}
			}
			.switcher-with-Dropdown {
				justify-content: space-between;
				flex-wrap: wrap;

				.form-inline {
					flex: 0 0 100%;
					.form-group {
						width: 100%;
					}
				}
			}
		}

		.campaign-builder-sec {
			.sub-header {
				height: auto;
			}
		}

		.camp-builder-edit-title {
			max-width: 170px;
		}

		.edit-campaign-name {
			padding-left: 0;
			max-width: 180px;

			.edit-icon {
				opacity: 1;
				display: unset;
				top: 3px;
				right: 2px;
			}
		}

		.search-edit-col-sec {
			.row {
				.col:first-child {
					flex: 1 1 0;
				}
				.col:last-child {
					flex-shrink: 1;
				}
			}

			.form-group {
				margin-bottom: 5px;
				width: 100%;
				margin-right: 0 !important;
			}

			.css-2b097c-container {
				min-width: 100px;
			}
		}

		.campaign-builder-sec {
			.zoom-unit {
				left: 15px;
			}
		}
		.workflow-area-new {
			.workflow-stage-sub-box-wrap {
				padding: 20px 10px;

				.workflow-stage-sub-box {
					padding: 20px;
				}
			}
		}
		.workflow-area-new .workflow-stage-sub-box-wrap .workflow-stage-sub-box + .workflow-stage-sub-box {
			margin-top: 15px;
		}

		.sidebar {
			z-index: 999;
			width: 100%;
		}

		.drop-file-box {
			height: auto;
		}
		.add-filters-btn {
			bottom: -25px;
		}
		td.action-col-sticky {
			z-index: 1;
		}
		.add-quote-item-table-outer {
			overflow: auto;
			margin-left: -15px;
			margin-right: -15px;
			min-height: 140px;

			.table-out-modal {
				margin: 0;
			}

			.w-35 {
				min-width: 180px;
			}
			td {
				max-width: 100%;

				input {
					min-width: 80px;
				}
			}
		}

		.with-sidebar {
			.sidebar {
				.sidebar-header {
					padding: 1rem;
					height: auto;

					i {
						font-size: 1rem;
					}
				}
			}

			&.webhook-drawer-sidebar {
				.sidebar {
					width:100%;
				}
			}
		}
	}
}

@media (max-width: 320px) {
  body {
		.navbar {
			.navbar-brand {
				img {
					max-width: 80px;
				}
			}
			.navbar-nav {
				&.admin-action-navbar {
					.dropdown-toggle {
						padding: 0.68rem 0.7rem;
					}
				}
			}
		}
		.btn {
			padding-left: 0.5rem;
			padding-right: 0.5rem;
		}
	}
}