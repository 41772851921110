.payment-failed-box {
  color: red;
  background: #ffeded;
}
.payment-failed-box button, .payment-failed-box button:hover, .payment-failed-box button:active, .payment-failed-box button:focus {
  background-color: #fceded !important;
  color: red !important;
  border: 1px solid !important;
  font-weight: 600;
}
